import React from 'react';
import {Link, PageProps} from 'gatsby';
import Layout from '@/components/Layout';

const About: React.FC<PageProps> = () => (
   <Layout pageTitle="NILA">
      <section className="event-virtual-booth">
         <div className="row center-xs">
            <div className="col-xs-12 col-sm-9 col-lg-9 text-center">
               <h1>NILA</h1>
               <img src="https://via.placeholder.com/1000x150" alt="Techstars"/>
            </div>
         </div>
         <div className="row center-xs margin-top-l hidden">
            <div className="col-xs-12 col-sm-9 col-lg-4">
               <div className="job-listing">
                  <a href="/">
                     <h4>Sr. Backend Engineer</h4>
                  </a>
                  <p>Full-time, San Francisco</p>
                  <p>Curabitur vitae est ac eros convallis tincidunt ut ac massa. Morbi scelerisque neque in sem
                  efficitur, non pulvinar mauris dignissim. Sed convallis vel turpis maximus vehicula. Sed vel.
                  </p>
               </div>
               <div className="job-listing">
                  <a href="/">
                     <h4>Sr. Full Stack Engineer</h4>
                  </a>
                  <p>Full-time, San Francisco</p>
                  <p>Curabitur vitae est ac eros convallis tincidunt ut ac massa. Morbi scelerisque neque in sem
                  efficitur, non pulvinar mauris dignissim. Sed convallis vel turpis maximus vehicula. Sed vel.
                  </p>
               </div>
               <div className="job-listing">
                  <a href="/">
                     <h4>Sr. Backend Engineer</h4>
                  </a>
                  <p>Full-time, San Francisco</p>
                  <p>Curabitur vitae est ac eros convallis tincidunt ut ac massa. Morbi scelerisque neque in sem
                  efficitur, non pulvinar mauris dignissim. Sed convallis vel turpis maximus vehicula. Sed vel.
                  </p>
               </div>
            </div>
         </div>
      </section>
   </Layout>
);

export default About;
